'use strict';

angular.module('service.transfer-account-data-provider', [
    'model.Account',
    'model.BankAccount'
  ])
  .factory('transferAccountDataProvider', [
    '$filter',
    factory
  ]);

function factory($filter) {
  var _currentUser;
  var _accounts;
  var _bankAccounts;

  var _getModernAdvisorAccountId = function(mode, transferInstruction) {
    var id = (mode === 'from' ? transferInstruction.transferFromId() : transferInstruction.transferToId());
    return id;
  };

  var _getModernAdvisorAccount = function(accountId) {
    return _.find(_accounts, function(account) {
      return account.id === accountId;
    });
  };

  var _getBankAccountId = function(mode, transferInstruction) {
    var id = (mode === 'from' ? transferInstruction.transferFromId() : transferInstruction.transferToId());
    return id;
  };

  var _getBankAccount = function(bankAccountId) {
    return _.find(_bankAccounts, function(bankAccount) {
      return bankAccount.id === bankAccountId;
    });
  };

  var _getCancelText = function(transferInstruction, options) {
    if (transferInstruction.frequency.is.onlyOnce()) {
      return '';
    }

    var output;
    if (transferInstruction.manualCancel()) {
      output = 'until cancelled';
    } else if (transferInstruction.maxOccurrence()) {
      output = 'for ' + transferInstruction.maxOccurrence() + ' transfers';
    } else {
      output = 'ending ' + $filter('date')(transferInstruction.continueUntil());
    }

    if (options && options.capitalize) {
      output = output.charAt(0).toUpperCase() + output.slice(1);
    }

    return output;
  };

  var _getConfirmationMessage = function(transferInstruction) {
    if (!transferInstruction) {
      return null;
    }
    if (transferInstruction.frequency.is.semiMonthly()) {
      return 'Your transfers will occur on the 1st and 15th of the month.';
    }
    return null;
  };

  function getDateLabel(transferInstruction) {
    if (transferInstruction.frequency.is.onlyOnce()) {
      return 'Date';
    } else {
      return 'Start Date';
    }
  }

  function setInputs(currentUser, accounts, bankAccounts) {
    _currentUser = currentUser;
    _accounts = (accounts.length > 1 && accounts[0].title === 'Combined') ? accounts.slice(1) : accounts;
    _bankAccounts = bankAccounts;
  }

  function getAccount(whichAccount, transferInstruction) {
    var type;
    var id;
    if (whichAccount === 'from') {
      type = transferInstruction.transferFromType();
    } else {
      type = transferInstruction.transferToType();
    }
    if (type === 'Account') {
      id = _getModernAdvisorAccountId(whichAccount, transferInstruction);
      return _getModernAdvisorAccount(id);
    } else {
      id = _getBankAccountId(whichAccount, transferInstruction);
      return _getBankAccount(id);
    }
  }

  function getAccountById(accountType, accountId) {
    if (accountType === 'Account') {
      return _getModernAdvisorAccount(accountId);
    } else {
      return _getBankAccount(accountId);
    }
  }

  function hasUnopenedAccount(transferInstruction, accountFrom, accountTo) {
    return (transferInstruction.transferFromType() === 'Account' && !accountFrom.status.is.open()) ||
      (transferInstruction.transferToType() === 'Account' && !accountTo.status.is.open());
  }

  function getConfirmationData(transferInstruction) {
    var accountFrom = getAccount('from', transferInstruction);
    var accountTo = getAccount('to', transferInstruction);
    var data = {
      fromAccount: accountFrom,
      toAccount: accountTo,
      confirmationMode: 'dialog',
      displayAmount: Math.abs(transferInstruction.amount()),
      frequency: transferInstruction.frequency().label,
      dateLabel: getDateLabel(transferInstruction),
      cancelText: _getCancelText(transferInstruction, {
        capitalize: true
      }),
      confirmationMessage: _getConfirmationMessage(transferInstruction),
      hasUnopenedAccount: hasUnopenedAccount(transferInstruction, accountFrom, accountTo),
      displayTransferId: getDisplayTransferId(transferInstruction),
      transferInstruction: transferInstruction,
      accountLabel: '',
      accountNumber: '',
      confirmMessage: ''
    };

    if (transferInstruction.transferFromType() === 'Account') {
      var accountLabel = accountFrom.type().label;
      var accountNumber = accountFrom.number();
      var confirmMessage = '';
      if (accountFrom.type.is.individualRrsp() || accountFrom.type.is.spousalRrsp()) {
        confirmMessage = 'RRSP';
      } else if (accountFrom.type.is.tfsa()) {
        confirmMessage = 'TFSA';
      }
      _.extend(data, {
        accountLabel: accountLabel,
        accountNumber: accountNumber,
        confirmMessage: confirmMessage
      });
    }

    return data;
  }

  function getDisplayTransferId(transferInstruction) {
    if (!transferInstruction.id) {
      return '';
    }
    return s.sprintf('%03d-%05d', getAccountById(transferInstruction.transferFromType(), transferInstruction.transferFromId()).id, transferInstruction.id);
  }

  function getDetailsMessage(transferInstruction) {
    return transferInstruction.frequency().label + ' ' + _getCancelText(transferInstruction);
  }

  return {
    setInputs: setInputs,
    displayId: getDisplayTransferId,
    getAccountById: getAccountById,
    getConfirmationData: getConfirmationData,
    getDateLabel: getDateLabel,
    getDetailsMessage: getDetailsMessage
  };
}
