'use strict';

angular.module('routes.advise.create-client', [
    'controller.advise.create-client',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);

function configRoutes($stateProvider) {
  $stateProvider
    .state('advise.create-client', {
      url: '/create-client',
      data: {
        tracking: {
          pageTitle: 'Create a new Client',
          pageId: 'client-profile-create',
          pageType: 'client-create',
          contents: {
            category: { primary: "clients" },
            content: [{
              type: "form",
            }],
          },
          forms: [{
            id: "client-create",
            name: "Create a new Client",
            type: "registration"
          }]
        }
      },
      abstract: true,
      controller: 'AdviseCreateClientCtrl',
      template: '<div id="create-client" class="container" ui-view></div>'
    })
    .state('advise.create-client.email', {
      url: '/email',
      controller: 'AdviseCreateClientEmailCtrl',
      templateUrl: 'views/advise/create-client/email.html'
    })
    .state('advise.create-client.success', {
      url: '/success',
      templateUrl: 'views/advise/create-client/success.html',
      data: {
        tracking: {
          pageTitle: 'Invitation successfully sent',
          pageId: 'client-profile-invitation-sent',
          pageType: 'summary',
          contents: {
            content: [{
              type: "summary",
            }],
            category: { primary: "clients" }
          }
        }
      }
  });
}
