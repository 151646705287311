'use strict';

angular.module('directive.input.date-input', [])
  .directive('dateInput', [
    '$timeout',
    dateInput
  ]);

/* global moment, $ */

function dateInput($timeout) {

  return {
    require: 'ngModel',
    restrict: 'E',
    scope: {
      ngModel: '=',
      ngChange: '&',
      title: '@',
      label: '@',
      class: '@',
      labelClass: '@',
      inputClass: '@',
      inputControlClass: '@',
      helpClass: '@',
      onFocusDo: '&',
      minDate: '@',
      maxDate: '@',
      doPresentOrFutureCheck: '@',
      optional: '='
    },
    templateUrl: 'directives/input/date.html',
    controller: ['$scope', 'adaptiveViews', function($scope) {
      var inputId;
      var _getFormattedDate = function(unformattedDate) {
        var intermediate = moment(unformattedDate, 'MM-DD-YYYY', true);
        if (intermediate.isValid()) {
          return intermediate.toDate();
        }
        return null;
      };

      $scope.model = function(val) {
        if (val === undefined) {
          var modelDate = moment($scope.ngModel());
          if (!modelDate.isValid()) {
            return $scope.rawDate;
          } else {
            return modelDate.format('MM-DD-YYYY');
          }
        } else {
          $scope.rawDate = val;
          var intermediate = _getFormattedDate(val);
          $scope.ngModel(intermediate);
        }
      };

      $scope.$watch('minDate', function() {
        var minDate = $scope.minDate ? moment($scope.minDate, 'MM-DD-YYYY', true).toDate() : new Date();

        $timeout(function() {
          var $el = $('input[name="date' + inputId + '"]');
          if (minDate > $scope.ngModel()) {
            $el.datepicker('setDate', minDate);
          }
          $el.datepicker('option', 'minDate', minDate);
        });
      });

      $scope.initDatePicker = function(id) {
        inputId = id;
        var dateToday = new Date();
        var minDate = $scope.minDate ? moment($scope.minDate, 'MM-DD-YYYY', true).toDate() : dateToday;
        var maxDate = $scope.maxDate || moment().add(100, 'y').toDate();

        $timeout(function() {
          var $el = $('input[name="date' + id + '"]');
          $el.datepicker('setDate', minDate);
          $el.datepicker({
            changeMonth: true,
            changeYear: true,
            dateFormat: 'mm-dd-yy',
            minDate: minDate,
            maxDate: maxDate
          });
        });
      };

      $scope.onFocusMethod = function() {
        $scope.onFocusDo();
      };
    }]
  };
}
