
class DataLayerService {

  dataSet = {
    business:{
      brand: 'XYZ-financial',
      family: 'XYZ',
    },
    user:{
      language: 'en',
      country: 'ca',
      account:{
        group:{},
      },
      session: '[Session ID]',
    },
  }
    
  constructor(user, userEvent, userData={}) {
    this.dataSet.event = userEvent;
    this.userData = userData;
    user ? this.setUserData(user) : this.dataSet.user.state = 'visitor';
    this.dataLayer = window.dataLayer || [];
  }

  setUserData(user) {
    this.dataSet.user.state =  'authenticated';
    this.dataSet.user.account.type = user.roleName() || 'account Type//tfsa--advisor-';
    this.dataSet.user.account.group.name = user.roleName() || 'Group Name -> part of multiple group ';
    this.dataSet.user.account.group.role = user.roleName() || 'Group Role';
    Object.keys(this.userData).length  && (this.dataSet = {...this.dataSet, ...this.userData});
  }
  push() {
    this.dataLayer.push(this.dataSet);
  }
}

module.exports = DataLayerService;
